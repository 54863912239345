import logo from '../assets/images/avatar1.png'
import headshot from '../assets/images/Smiling_Professional-min.jpg'
import { faFacebook, faInstagram, faTwitter, faYoutube, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons'

const constants = {
    BROKERAGE_NAME: 'Remax Reality',
    BROKERAGE_NUMBER: '123 456-7890',
    BROKERAGE_EMAIL: 'help@remax.com',
    MAP: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.349892824555!2d-79.43980422382172!3d43.68248877110062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3477fc9e0bb9%3A0x870c670971503362!2s228%20Oakwood%20Ave%2C%20Toronto%2C%20ON%20M6E%202V4!5e0!3m2!1sen!2sca!4v1733258947456!5m2!1sen!2sca",
    SOCIALS: {
        'twitter': 'https://www.x.com',
        'facebook': 'https://www.x.com',
        'instagram': 'https://www.x.com',
        'youtube': 'sfsfsf',
        'linkedIn': 'fssfsf',
        'tikTok': 'sfsfsf'
    },
    ICONS: { twitter: faTwitter, facebook: faFacebook, instagram: faInstagram, youtube: faYoutube, linkedIn: faLinkedin, tikTok: faTiktok },
    LOGO: logo,
    HEADSHOT: headshot,
    AGENT_ID: '3425022',
    OFFICE_ID: '347200',
    // TOKEN: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMyMDM1MjYwLCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiNWRjYWMyMzI5ZDZiZDllMiIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.hi1h3xh8cJnCbq0kyrH3PnGZD1mR08FXd4T3Lx2xiy8',
    TOKEN: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4',
    URL: '67.223.102.95:3000',
    // ENVRIONMENT: 'local',
    ENVRIONMENT: 'prod',
    EMAIL_ACCESS_KEY: 'ce242f9a-5573-49b8-b802-84368d601c83',
    ABOUT: 'about me text',
    AGENTS: [
        {
            name: 'Luke Agent', title: 'Realtor', img: headshot, socials: {
                'twitter': 'https://www.x.com',
                'facebook': 'https://www.x.com',
                'instagram': 'https://www.x.com',
            },
            website: 'www.google.com',
            number: '123 456-7890',
            email: 'luke@mail.com',
            bio: 'My name is Luke dsd sds dsd sdsd dsd dsdsd sd sdsdd dsdsd dsdsd dsdsd dsdsd sdsd dsdsdsd a a a a a a aaaaaaaaa aaaaaaaaaaaaa aaaaaaaaaaaaaa a aaaaaaaaa aaaaaaaaaaa aaaaaa ffffff ffffffff fffff f d d d d d dddddddddd dd ddd ff f fff fff ffffff ffffffffff p p p p p p p p p g',
            email_key: '12345',
        },
        { name: 'Amy Adams', title: 'Broker', img: headshot },
        {
            name: 'Brad Jones', title: 'Realtor', img: headshot, socials: {
                'twitter': 'https://www.x.com',
                'facebook': 'https://www.x.com',
               
            },
            website: 'www.google.com',
            number: '123 456-7890',
            email: 'luke@mail.com',
            bio: 'My name is Brad dsd sds dsd sdsd dsd dsdsd sd sdsdd dsdsd dsdsd dsdsd dsdsd sdsd dsdsdsd a a a a a a aaaaaaaaa aaaaaaaaaaaaa aaaaaaaaaaaaaa a aaaaaaaaa aaaaaaaaaaa aaaaaa ffffff ffffffff fffff f d d d d d dddddddddd dd ddd ff f fff fff ffffff ffffffffff p p p p p p p p p g',
            email_key: '12345',
        },

        
        { name: 'Will Jones', title: 'Realtor', img: headshot },
        { name: 'Amy Adams', title: 'Broker', img: headshot },
    ],
    SELLING_GUIDE: [{heading: 'Selling Your Home', text: 
        "<p>There are a million different reasons why people sell their homes, but every seller has one thing in common: the desire to get as much money as possible from their existing residence as quickly and as hassle-free as possible. (If your home is your principal residence, you won't have to pay capital gains tax on any profits from the sale. If, on the other hand, it is an investment property, prepare for the tax man!) Before you begin the selling process, really evaluate why you're moving. Do you have too few rooms, or too many? Has your job moved to another city and you're relocating? Are the neighbours driving you away? Or are you simply looking for a change? A complete analysis of your current position will set a good foundation for your next home hunt.</p>\
        <br/>\
        <h3 style='font-size: 1.75em'>Buy or Sell First?</h3>\
        <br/>\
        <p>Buy or sell first? That's tricky. After all, if you find a purchaser for your existing home, before you've found a new one, you may find yourself living out of a suitcase if convenient closing dates cannot be negotiated. On the other hand, if you find your dream home before you have sold your existing home, you may be faced with carrying two mortgages for a time. So how do you manage? Easy. Do your homework and have a good idea about the neighbourhood and type of home you're looking for. Do an honest evaluation of your family's needs and budget. Speak to your sales representative and start your new home search as soon as your existing home hits the market. If you've found a home, before you've sold your existing one, use 'sale of your existing home' as a condition on your offer. If you don't sell your house within a fixed period of time, you can choose not to go through with the offer. This, however, is a difficult condition for many vendors to agree upon and you may find that you have to forgo your price negotiating power. Purchasing a home before you sell could be a risky strategy if you're counting on the proceeds from the sale. If you've found a purchaser before you've found your next home, use 'purchase of a new home' as a condition when you sign back the agreement. Again, it will only be for a fixed time. Even if you have not found the ideal next house by the time the deal closes, you may still wish to proceed with the offer. As a buyer with a sold house you will be in a better position to negotiate</p>\
        <br/>\
        <h3 style='font-size:1.75em'>Make Your House More Sellable<h3>\
        <br/>\
        <p>While we all believe that our home is our castle, our personal tastes may not appeal to everyone. Your sales representative will work with you to give you an impartial analysis of your home - how it relates to other \"competing\" homes on the market and how your home reflects current design and style trends. Your sales representative will also take a good look at the general condition and upkeep of your dwelling. Overall, your sales representative will work with you to position your home on the market so that your sales experience will take place as expeditiously as possible.</p>\
        <br/>\
        <h3 style='font-size:1.75em'>Advertise Your Home on Various Internet Real Estate Portals<h3>\
        <br/>\
        <p>Your home will be advertised on many Internet Real Estate Portals to ensure that the details of your home will be seen by a large number of potential buyers.</p>\
       <br/>\
        <h3 style='font-size:1.75em'>Arrange Showings<h3>\
        <br/>\
        <p>In addition to giving your home the once over with a mop and dust cloth, have your sales representative prepare a home feature sheet. This is a one-page synopsis of your home that highlights lot size, room dimensions, features and upgrades as well as utility costs and taxes. Prospective buyers will take a sheet and refer to it while viewing your home. Plus, it makes for a great reference sheet when the buyers are comparing properties. The Open House: This is usually not a pleasant experience. The upside is that you usually won't have to be there to act as a guide. The downside is that you might have a series of open houses over a few weeks, with people poking through all areas of your home. And you'll have the constant pressure of keeping your home looking its best. The Viewing: When a sales representative has a client who is interested in your home, they will first call to make an appointment with your sales representative. If you're lucky, you'll have time for the last-minute tidying. Of course, you can say no if the prospective purchaser wishes to come at an inconvenient time. During the viewing, make sure you give the viewer and the sales representative a feature sheet and get the sales representative's business card. Give viewers the freedom to wander around your home by themselves. Following may make them uncomfortable. The sales representative will stay with the prospects to offer some protection against theft or property damage.</p>\
        "
    
    },
    {heading: 'Choosing the Right Sales Representative', text: 
        "<p>Our sales representative is a trained professional who knows all aspects of the real estate market. A sales representative will save you time, money and aggravation. As with purchasing a home, you want to list with the sales representative who is the expert in your location. After all, potential purchasers will be calling this same \"area expert\" to inquire about houses for sale. There will be a few sales representatives who are knowledgeable about your neighbourhood. Call them up and interview them. You need to feel comfortable with him or her, after all, they will be working for you.</p>\
        <br/>\
        <h3 style='font-size: 1.75em'>Should You Go With a Non-Exclusive or Exclusive Listing Arrangement?</h3>\
        <br/>\
        <p>If you enter into this type of arrangement with your sales representative, you are giving him or her the exclusive right to find a purchaser for your home. With this type of agreement, no other sales representative will bring potential buyers to your home, because only the listing sales representative is entitled to the commission. You may consider this type of arrangement in a Sellers' Market during which time there are more people interested in purchasing a home than there are homes available.</p>\
        <br/>\
        <h3 style='font-size:1.75em'>Understand Market Conditions<h3>\
        <br/>\
        <p>The real estate market is in constant flux, not only as a whole but in particular areas as well. Knowing what is going on in the overall and local real estate markets will help you understand how these conditions can affect the sale of your home. We've designed the following comparison to help give you an overview of the three significant market positions. When you meet with your sales representative, ask about the current state of the market.</p>\
        <br/>\
        <h3 style='font-size:1.75em'>Buyer's Market:<h3>\
        <br/>\
        <p>The supply of homes on the market exceeds demand.Characteristics: High inventory of homes. Few buyers compared to availability. Homes usually stay on the market longer. Prices are stable or perhaps dropping. Implications: Buyers spend more time looking for a home, and when they negotiate, they usually have more leverage.</p>\
       <br/>\
        <h3 style='font-size:1.75em'>Seller's Market:<h3>\
        <br/>\
        <p>The number of potential buyers exceeds the supply of homes on the market. Characteristics: There is a smaller inventory of homes with many buyers. Homes sell quickly. Prices usually increase. Implications: Prices may be higher or perhaps climbing. Buying decisions must be made quickly. Conditional offers may be rejected.</p>\
        <br/>\
        <h3 style='font-size:1.75em'>Balanced Market:<h3>\
        <br/>\
        <p>The number of homes on the market is roughly equal to the demand. Characteristics: Demand equals supply. Sellers accept reasonable offers. Homes sell within a reasonable time period. Prices generally remain stable. Implications: There is less tension among buyers and sellers. There is a reasonable number of homes to choose from.</p>\
        "
    },
    {heading: 'Closing the Sale', text: 
       
        "<h3 style='font-size: 1.75em'>Home</h3>\
        <br/>\
        <p>Closing is a time of packing and organization. Be sure you do not pack anything that you agreed to sell! Unless you specifically mentioned certain fixtures, everything must remain in place. You are responsible for handing over the home in the same condition it was at the time of closing. This applies to everything that was in the agreement. If the home suffers a major disaster, you are responsible for telling the buyer, at which point the buyer may walk away from the deal and have the deposit returned. The buyer may also choose to close and receive any insurance proceeds. In this unfortunate event, remember not to make any repairs until you find out what the buyer wants to do.</p>\
        <br/>\
        <h3 style='font-size:1.75em'>The Closing<h3>\
        <br/>\
        <p>Once signing the agreement, both the seller and buyer are under a legal obligation to close. If you decide not to for whatever reason, the buyer has the right to sue. If the buyer decides to walk away from the deal, you can claim the buyer's deposit or sue for damages.</p>\
        "
    }
],
BUYING_GUIDE: [{heading: 'Getting Started', text: 
    "<p>The first question you're bound to ask is, \"How much home can I afford?\" That depends on a number of factors: Your selected location. Are you set on a specific area? Downtown? The suburbs? A rural setting? Your preferred type of home. Detached? Semi? Duplex? High-rise? Link? Townhouse? New or Resale? There are a variety of home styles you will want to explore. Your income. After all, it's not just the mortgage you have to take into account. There are property taxes, utilities, and in some cases condo or strata fees. As a general rule of thumb, your monthly home-carrying cost should not exceed 30-35% of your income. Market conditions. Is it a buyers', sellers' or balanced market? There are also additional costs to keep in mind. It's a good idea to work out exactly what you want and what you can afford before you begin the search. Be specific! After all, you don't want to suddenly come to the realization that your dream house has come with a nightmare of bills and expenses. Stick to looking at houses in your price range. The more you've thought it out, the better your sales representative can meet your needs. A part of deciding just what you can afford can be accomplished by meeting with your bank or a mortgage broker and negotiating a pre-approved mortgage. There are many types of mortgages and many different terms. Research all of your options. This ensures that there are no surprises once you're ready to make an offer. Once you've figured out your monthly expenses and what you can afford, you can start your search. It could happen that the first home you see is the one you want; or you might look at home after home with none of them catching your interest. Rest assured, the home you're looking for is out there, and when you find it, you're ready to make an offer. If your offer is accepted, the next steps are closing and moving into your new home. Purchasing a home is easy once you put your plans into action.</p>\
    "

},
{heading: 'What Can I Afford?', text: 
    "<p>Homes come in every size, style and price range. Knowing what you can afford at the beginning of your search saves you time and disappointment later on. The following calculations outline the process financial institutions use to determine what you can afford.</p>\
    <br/>\
    <h3 style='font-size: 1.75em'>First Affordability Rule</h3>\
    <br/>\
    <p>Lenders such as banks and trust companies allow you to spend approximately 32%* of your gross monthly income on housing costs (including property taxes, heating and, if applicable, 50% of condominium fees. The ratio of debt to income is referred to as the Gross Debt Service ratio or GDS.\
<br/>\
The following calculation will you how much you can afford monthly for housing.\
<br/>\
Your gross monthly income __________________________\
<br/>\
Spouse’s gross monthly income ______________________\
<br/>\
Other monthly income ______________________________\
<br/>\
Total monthly income _______________________________\
<br/>\
Monthly income x 32% = GDS ________________________</p>\
    <br/>\
    <h3 style='font-size:1.75em'>Second Affordability Rule<h3>\
    <br/>\
    <p>The second affordability rule is that your entire monthly debt load shouldn't be more that 40% of your gross monthly income. This includes housing costs and other debts, such as car loans and credit card payments. Lenders add up these debts to determine what percentage they are of your gross household monthly income. This is your Total Debt Service (TDS) ratio.\
<br/>\
<br/>\
The following calculation will you what you can afford for housing including your outstanding debts:\
<br/>\
<br/>\
A) Monthly Income from your GDS Calculation Above x 40% = TDS____________________\
<br/>\
B) Add up your monthly payments for loans, credit cards and other debts__________________\
<br/>\
Monthly income left for housing costs (subtract the amount from (A) from the amount in (B).\
<br/>\
In addition to GDS and TDS ratios, financial institutions base their lending decision on your credit history, job stability and the amount of your down payment. Interest rates also affect the amount of financing you will be able to obtain.\
<br/>\
<br/>\
*Please note that many lenders are prepared to exceed these guidelines.</p>\
"
},
{heading: 'Your Buyer Team and Their Roles', text: 
   
    "<h3 style='font-size: 1.75em'>Sales Representatives</h3>\
    <br/>\
    <p>A real estate sales representative is a professional who can save you time and trouble. And possibly even a lot of money. You see, real estate sales representatives have the home buying experience most people lack. They know all of the steps and they are good negotiators who will work on your behalf.\
<br/>\
<br/>\
A sales representative will:\
<br/>\
<br/>\
<ul >\
<li>\
Fine-tune your wants/needs list\
</li>\
<li>\
Get special computer access to listing information\
</li>\
<li>\
Screen houses so as not to waste your time\
</li>\
<li>\
Arrange appointments\
</li>\
<li>\
Offer helpful advice about the neighbourhood\
</li>\
<li>\
Introduce you to trusted contacts who should be on your team, such as mortgage brokers, lawyers, and home inspectors.\
</li>\
</ul>\
<br/>\
Above all, find a real estate sales representative who is a professional in the type of home you're looking for. A country home professional may not be the urban market specialist you need. And when speaking with your sales representative, be as clear as possible about your needs</p>\
    <br/>\
    <h3 style='font-size:1.75em'>Contractors<h3>\
    <br/>\
    <p>If you've decided to do some renovations on your home to make it more sellable, it's time to look for a credible contractor. Before anyone begins work on your home, it is important to do your homework.\
<br/>\
<br/>\
<span style='font-weight: 600'>Ask for Referrals</span>\
<br/>\
<br/>\
Your architect will make recommendations Your sales representative will offer some suggestions Contact friends or neighbours who have had similar work done Ask at your local builder supply store When you're interviewing contractors, ensure their credibility. Contact their references. Ask to see some samples of the contractor's work and speak to his clients to ensure that they were satisfied with the price, length of time in which the project was completed and overall, how the project was handled. Also, check with your local Better Business Bureau. Once you have the names of a few contractors that look promising, arrange to get estimates from them. By arranging for three quotes you'll get a good idea of the costs and quality of work. When going over the project with your contractor, ensure that he understands your needs and your budget. Each contractor will have a different idea on how to approach the work and they should inspect your home before giving an estimate. If contractors are bidding based on an architect's plans, be certain that they have detailed their approach to the job based on the drawings. What's more, if there is a significant difference in the price, ask the contractors to explain their estimates. And keep in mind that the lowest price is not always the best. A price that's too low may mean that the contractor has undercut to get the project and then may submit additional project costs once the project is underway. As well, a high price doesn't always mean that you're getting gouged. The contractor may have budgeted for higher quality materials and may offer workmanship that is of an overall better quality. In every case, before you sign the contract, be certain that it is as detailed as possible to the point of noting the specific finishes and brand names of the products to be installed.\
    <br/>\
    <br/>\
<span style='font-weight: 600'>Evaluating a Quotation</span>\
<br/>\
<br/>\
<ul>\
<li>\
Are the specific details of the project outlined?\
</li>\
<li>\
Are the specific costs detailed?\
</li>\
<li>\
Is there a provision for extra costs?\
</li>\
<li>\
Has a cap been set for the total project?\
</li>\
<li>\
Is there a firm project timeline?\
</li>\
<li>\
Has the contractor allotted time for inspections?\
</li>\
<li>\
Have you indicated that you wish to see all material receipts?\
</li>\
<li>\
Will the work be subcontracted?\
</li>\
</ul>\
</p>\
<h3 style='font-size: 1.75em'>Appraisers</h3>\
        <br/>\
        <p>Hiring an appraiser to appraise the value of a property you are considering to buy may seem sensible but it is highly unnecessary. Your lender will want their own personal appraiser anyway, so you could be wasting valuable money. As well, most sales representatives are competent and can do a \"Comparative Market Analysis\" for you, to establish a value range. The only situation where hiring an appraiser would become necessary is where the property is unusual with no comparable sales.</p>\
        <br/>\
        <h3 style='font-size:1.75em'>Lenders<h3>\
        <br/>\
                <p>The true test for a buyer is \"What else can we buy for the same or less money?\" In short, a lender is anyone who will give you money. There are private lenders and institutional lenders, like banks and credit unions. Even your brother-in-law can be your lender. Of course, when you're looking for a lender, you are looking for a long-term relationship and terms and rates which are beneficial to you. You really have a few options. You should go to a mortgage broker who will search the mortgage market for the best rates and conditions based on your circumstances. Usually, the broker is paid by the lender without cost to you. However, the cloudier your credit history, the more likely there will be a fee! A good mortgage broker will be connected to all major lenders through the mortgage market. You can also do your own search. With a good credit history, it's really not that complicated. Pick up your newspaper and you'll see what the different lending institutions are offering. Find the institution you feel you would be most comfortable with and one that offers the terms and conditions you're looking for. Then, go in person and negotiate your best deal. We'll go into more detail about this process in the arranging a mortgage section.</p>\
        <br/>\
        <h3 style='font-size: 1.75em'>Mortgage Brokers</h3>\
        <br/>\
        <p>This is a person who will do the work in finding the institution which offers the mortgage terms and conditions that are right for you. Much like an insurance broker, this professional works for you and can offer you an unbiased referral. Although most brokers are paid a finders fee by the lender, some will charge 2% of the total mortgage to find you a lender.</p>\
        <br/>\
         <h3 style='font-size: 1.75em'>Lawyers/Notaries</h3>\
        <br/>\
        <p>A lawyer is there to represent your interest, and to process the documentation required. The legal aspects differ from province to province. Your sales representative can recommend lawyers to advise you on the steps to be taken before the keys to your new home are presented to you. A lawyer helps ensure you are protected!</p>\
        <br/>\
         <h3 style='font-size: 1.75em'>Home Inspectors</h3>\
        <br/>\
        <p>Have the home inspected! Whether you make it a condition of purchase or not, having the property pre-inspected by a qualified home inspector will give you the added confidence that you've made the right decision. Be very careful to verify the qualifications of your home inspector because there are no government standards or licenses for home inspectors. Some home inspectors in Canada do not have any form of accreditation. For your protection make sure your home inspector is a member of (PACHI) or (OAHI). This is your assurance that they have met their education requirements, have the experience and carry E & O Insurance.</p>\
        <br/>\
         <h3 style='font-size: 1.75em'>Insurance Brokers</h3>\
        <br/>\
        <p>You'll want to make sure your property and valuables will be covered. A broker offers independent advice and can save you time, trouble and money. Plus, the bank will insist that you carry full insurance since your property is used as collateral against your mortgage.</p>\
        <br/>\
"
},
{heading: 'Mortgage Types', text: 
    "<h3 style='font-size:1.75em'>Conventional and High Ratio Mortgages<h3>\
    <br/>\
    <p>To qualify for a conventional mortgage, you simply have to have a 20% down payment of the purchase price, with the mortgage not exceeding 80% of the appraised value. If your down payment is less than 20%, then you qualify for a high-ratio mortgage. This type of mortgage requires loan insurance, which can cost an additional 0.6% to 4.50% of the mortgage amount. With this type of mortgage, you could also be limited to a maximum house price.</p>\
    <br/>\
    <h3 style='font-size: 1.75em'>Second Mortgage</h3>\
    <br/>\
    <p>Of course, if you cannot add on to your mortgage, you may consider a second mortgage. Each mortgage uses your home as security and gives the mortgagee the right to take your home if you default on your loan. The first mortgagee gets paid first in cases of default and has the best chance of recovering all of its money. So it only goes to figure that subsequent mortgages usually come with a higher interest rate.</p>\
    <br/>\
    <h3 style='font-size:1.75em'>Mortgage Features<h3>\
    <br/>\
    <p>Every lending institution is different, and each will have their own customizable mortgage options. When you're hunting for a lender and a home, see how the following features could be beneficial to you.</p>\
    <br/>\
    <h3 style='font-weight:600'>Prepayment<h3>\
    <br/>\
    <p>This is a wonderful option if you receive regular bonuses or if your income fluctuates throughout the year. With a pre-payment privilege, you have the right to make payments toward the principal portion of your mortgage over and above the monthly payments. A mortgage with a pre-payment option is closed. An open mortgage means you can pay the entire principal sum without notice of bonus.</p>\
   <br/>\
    <h3 style='font-weight:600'>Portability<h3>\
    <br/>\
    <p>If you still have time remaining on that fantastic loan you negotiated, portability is one option you'll want to discuss with your lender. Quite simply, it means transferring the balance of your current mortgage at the existing rates and with the existing terms and conditions, to your new home.</p>\
    <br/>\
    <h3 style='font-weight:600'>Assumability<h3>\
    <br/>\
    <p>Let's say that the vendor has negotiated a dynamite mortgage. With an assumable mortgage you, the purchaser, simply assume the obligations of the mortgage. This is a wonderful feature especially if the terms are more favourable than the existing market conditions would allow. Remember, when it is time for you to sell, you may still be liable for any mortgage you allow the buyer to assume. This means if the buyer stops making payments, you could be accountable for the payments. Be sure to have the subsequent buyer approved for the assumption of the payments, thereby avoiding this potential land mine.</p>\
    <br/>\
    <h3 style='font-weight:600'>Expandability<h3>\
    <br/>\
    <p>If you need additional funds down the road, will your mortgage terms allow you to increase the principal amount? Usually, your new rate will be a blended amount of the initial mortgage rate and the prevailing rates. It's a great option to discuss with your lender if you foresee large expenses in your future like renovation or education costs.</p>\
    "
},


],
LOCATIONS: [
    {name: 'Mississauga', searchTerm: 'Mississauga', slug: 'mississauga'},
    {name: 'Burlington', searchTerm: 'Burlington', slug: 'burlington'},
    {name: 'Barrie', searchTerm: 'Barrie', slug: 'barrie'},
    {name: 'Markham', searchTerm: 'Markham', slug: 'markham'},
    
]

}

export default constants;
import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";
import Homepage from './components/home.component';
import Calculator from './components/calculator.component';
import Listing from './components/listing.component';
import Header from './components/header.component';
import Listings from './components/listings.component';
import About from './components/about.comoponent';
import Footer from './components/footer.component';
import Evaluation from './components/evaluation.component';
import Reports from './components/reports.component';
import Contact from './components/contact.component';
import Agents from './components/agents.component';
import CommercialListings from './components/commercial.component';
import SellerGuide from './components/sellerGuide.component';
import BuyerGuide from './components/buyerGuide.component';
import Location from './components/location.component';

function App() {
  return (
    <div >
      <HashRouter>
        <Header />
        <Routes>
          {/* <Route path="*" element={<Error />} /> */}
          <Route path="/" element={<Homepage />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/listing/:id" element={<Listing  />}/>
          <Route path="/listings" element={<Listings  />}/>
          <Route path="/agents/:name" element={<About  />}/>
          <Route path="/evaluation" element={<Evaluation  />}/>
          <Route path="/real-estate-reports" element={<Reports  />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/agents" element={<Agents />}/>
          <Route path="/commercial" element={<CommercialListings />}/>
          <Route path="/selling-guide" element={<SellerGuide />}/>
          <Route path="/buying-guide" element={<BuyerGuide />}/>
          <Route path="/location/:id" element={<Location />}/>

          </Routes>
          <Footer/>
          </HashRouter>
  
    </div>
  );
}

export default App;

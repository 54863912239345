import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import constants from "../constants/constants";
import logo1 from "../assets/images/2020_MLS_FINAL_WHITE.png"
import logo2 from "../assets/images/Realtor-logo-270x300.png"


export default function Footer() {
    const [menus, setMenus] = useState({ buying: false, selling: false, info: false })
    function setDropdown(key) {
        var temp = { ...menus }
        for (let k of Object.keys(temp)) {

            if (key === k) {
                temp[k] = !temp[k];
            } else {
                temp[k] = false;
            }
        }


        setMenus(temp)
    }
    return (
        <div className="bg-logo-second w-full">
            <div className=" w-full py-12 px-16 flex flex-wrap gap-x-20 gap-y-10 justify-between " >
                <div className="grid md:grid-cols-2 gap-x-24 w-fit gap-y-3 font-semibold child:relative mt-2 text-white">
                    <div className="flex flex-col gap-y-3">
                        <Link to={'/'}>Home</Link>
                        <Link to={'/agents'}>Our Agents</Link>
                        <Link to={'/contact'}>Contact</Link>

                    </div>
                    <div className="flex flex-col gap-y-3">
                        <div className="cursor-pointer relative" onClick={() => setDropdown('buying')}  >Buying <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.buying ? 'fa-flip-vertical' : ''}`} />
                            {menus.buying &&
                                <div className="text-black absolute bottom-8 z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/calculator'}>Mortgage Calculator</Link>
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/listings'}>Listings</Link>

                                </div>}
                        </div>
                        <div className="cursor-pointer relative" onClick={() => setDropdown('selling')}  >Selling <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.selling ? 'fa-flip-vertical' : ''}`} />
                            {menus.selling &&
                                <div className="text-black absolute bottom-8 z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/evaluation'}>Free Home Evaluation</Link>

                                </div>}
                        </div>

                        <div className="cursor-pointer relative" onClick={() => setDropdown('info')}  >Information <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.info ? 'fa-flip-vertical' : ''}`} />
                            {menus.info &&
                                <div className="text-black absolute bottom-8 z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/real-estate-reports'}>Real Estate Reports</Link>

                                </div>}
                        </div>

                    </div>


                </div>
                <div className="flex flex-col items-end">
                    <h1 className="text-2xl text-white pb-8 font-mont ">FOLLOW</h1>
                    <div className='flex gap-x-5 my-2'>
                        {Object.entries(constants.SOCIALS)?.map(([k, v]) =>
                            <a href={constants.SOCIALS[k]}><FontAwesomeIcon className='fa-xl text-white' icon={constants.ICONS[k]} /></a>
                        )}
                    </div>

                </div>

            </div>
            <div className="text-[#e0e0e0] pb-12 px-16">
                <div className="mb-3">
                    <img src={logo2} className="w-8 inline mr-2"></img>

                    <span>The trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA.</span>
                </div>
                <div className="mb-3">
                    <img src={logo1} className="w-12 inline mr-2"></img>

                    <span>The trademarks MLS®, Multiple Listing Service® and the associated logos are owned by The Canadian Real Estate Association (CREA) and identify the quality of services provided by real estate professionals who are members of CREA.</span>
                </div>
                <p >Presented by <a href="https://aptoconnect.com/">APTO Connect ©</a></p>
            </div>        </div>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import constants from '../constants/constants'
import { useLocation } from 'react-router-dom'

export default function About() {
    const { state } = useLocation()
    
    return (
        <div className="max-w-[1100px] mx-auto gap-x-24 gap-y-12 px-6 grid sm:grid-cols-2 my-12 min-h-[70vh]">
            <div>
                <img src={state.img} className='w-full'/>
                <div className="flex flex-col gap-y-2 text-black mt-6">
                        <h2 className="text-black text-3xl">{state.name}</h2>
                        {/* <p className="italic text-lg">{constants.AGENT_TITLE}, {constants.BROKERAGE}</p> */}

                        <div className='flex gap-x-5 my-2'>
                            {Object.entries(state.socials ?? {})?.map(([k, v])=>
                            <a href={constants.SOCIALS[k]}><FontAwesomeIcon className='fa-xl text-logo-second' icon={constants.ICONS[k]}/></a>
                            )}
                        </div>
                        <p className='font-semibold text-lg'>{state.number} </p>
                        <p className='text-lg'>{state.email} </p>
                    </div>
                   

            </div>
            <div>
            <h1 className="text-4xl text-black pb-8 font-mont ">ABOUT ME</h1>

            { state.bio ? state.bio.split('<|>').map((p)=>
            
            <p>{p}<br/><br/></p> 
            ) : <p>Coming Soon</p>}
            </div>

        </div>
    )
}
import constants from "../constants/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import placeholder from "../assets/images/house-placeholder.webp"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function Agents() {
    const [keyword, setKeyword] = useState('')
    function slugify(str) {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();
        str = str.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
        return str;
    }
  
    return (
        < div className="max-w-[1100px] mx-auto px-7 mb-10">
            <h1 className="text-4xl text-black pt-8 font-mont uppercase mt-6">Our Agents</h1>
            <p className="font-semibold text-slate-600 text-lg pb-10 pt-6">All of our agents are licensed by the official licensing board and have X years combined expierence etc (some blurb)</p>
            <div className="flex gap-6">
            <div className="relative pb-10">
                <input className="form-input" placeholder="Search Agents" value={keyword} onChange={(e) => setKeyword(e.target.value)} style={{ borderRadius: '99999px' }}></input>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute right-4 top-2 text-med-grey" />
            </div>
            {/* <Link >
                <button className="btn-primary">Search</button>
            </Link> */}
        </div>
        {!constants.AGENTS?.filter((a)=>a.name.toLowerCase().includes(keyword.toLowerCase()))?.length ?
        <h3 className="font-semibold text-align-center mt-12"><FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" /> No agents found</h3>
: null }
                 
        <div className="grid listing-grid-min gap-9 min-h-[35vh] ">
            {constants.AGENTS?.filter((a)=>a.name.toLowerCase().includes(keyword.toLowerCase()))?.map((agent) =>
                <Link className="w-full h-full" state={agent} to={`${slugify(agent.name)}`}>
                    <div className="relative w-full aspect-[5/3] sm:aspect-[4/3] overflow-hidden group">
                        <img className="w-full h-full object-cover" src={agent.img ?? placeholder} />
                        <div className="px-4 py-2 duration-1000 h-[100px] bg-logo-second bg-opacity-50 absolute bottom-0 w-full text-white group-hover:h-full">
                            <p className="text-xl font-mont font-semibold">{agent.name}</p>
                            <p className=" italic">{agent.title}</p>
                            <div className="flex gap-x-4 mt-2">
                                {Object.entries(agent.socials ?? {})?.map(([k, v], index) =>
                                    <a href={v}>
                                        <FontAwesomeIcon className="fa-lg" icon={constants.ICONS[k]} />

                                    </a>
                                )}
                            </div>
                            {/* <p className="mt-7 text-xl font-semibold">About {agent.name?.split(' ')[0]}</p>
                                <p className="mt-3">{agent.bio ? agent.bio.replace(/(<([^>]+)>)/gi, '')?.split(' ')?.splice(0, 50)?.join(' ') + " ..." : 'Coming Soon'}</p> */}
                        </div>
                    </div>
                </Link>

            )}
        </div>
        
        </div >
    )
}
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import constants from "../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
export default function SellerGuide() {
    const [open, setOpen] = useState(new Array(constants.SELLING_GUIDE.length).fill(false))

    return (
        <div className="max-w-[1100px] mx-auto px-7 mb-10 min-h-[55vh]">
            <h1 className="text-4xl text-black font-mont uppercase my-8 ">Guide for Sellers</h1>

            <div className="flex flex-col gap-y-3">
                {constants.SELLING_GUIDE.map((guide, index) =>
                    <div key={index} >
                        <div onClick={() => { var temp = [...open]; temp[index] = !temp[index]; setOpen(temp) }}  className="flex justify-between py-4 px-3 cursor-pointer bg-slate-200 items-center ">
                            <span className="font-semibold">{guide.heading}</span>
                            <FontAwesomeIcon className="fa-lg" transform={{ rotate: open[index] ? 180 : 0 }} icon={faAngleDown} />

                        </div>
                        {open[index] ?
                            <div className="py-8 px-3  bg-slate-100">
                                <div dangerouslySetInnerHTML={{
                                    __html: guide.text
                                }}>
                                </div>

                            </div> : null}


                    </div>)}
                {/* <div className="py-4 px-3 mt-3">
                            <span className="font-bold">Don't see your guide answered? <br/><br/></span>
                            <span className="font-semibold  "> Call our support line at 703 349-6405 for more information</span>

                        </div> */}

            </div>
        </div>
    )
}
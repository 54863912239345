import { Link, useLocation } from "react-router-dom"
import logo from "../assets/images/avatar1.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faBars, faCaretDown, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState, useRef } from "react"
import constants from "../constants/constants"
import formattingFunctions from "../functions/formatting"
export default function Header() {
    const [menus, setMenus] = useState({ buying: false, selling: false, info: false, locations: false })
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [headerPosition, setHeaderPosition] = useState(100)
    const [path, setPath] = useState(window.location.href.split('#')[1]);
    const [headerTiming, setHeaderTiming] = useState(.8)
    const location = useLocation();




    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    function setDropdown(key, bool) {
        var temp = { ...menus }
        for (let k of Object.keys(temp)) {

            if (key === k) {
                temp[k] = bool;
            } else {
                temp[k] = false;
            }
        }


        setMenus(temp)
    }

    function navClick() {
        setHeaderPosition(100);
        setHeaderTiming(0);
        setTimeout(() => { setHeaderTiming(.8) }, 100);
        // if (typeof window != 'undefined' && window.document) {
        //     document.body.style.overflow = 'auto';
        // }
        setMenus({ buying: false, selling: false, info: false, locations: false })
    }
    useEffect(() => {
        setPath(window.location.href.split('#')[1])
    }, [location])

    // const currentPathRef = useRef(window.location.pathname);

    // if (location.pathname !== currentPathRef.current) {
    //     window.scrollTo(0, 0);
    //     currentPathRef.current = location.pathname;
    // }


    return (
        <>
            {false ?
                <div style={{ gridTemplateColumns: "1fr auto 1fr" }} className="bg-logo-main w-full top-0 py-4 px-8 grid min-h-28 items-center  z-50">
                    <div className="rounded-full w-24 h-24 overflow-clip">
                        <img src={constants.LOGO} className="h-full w-full object-cover" />
                    </div>
                    <div className="flex gap-x-10 justify-center font-semibold child:relative mt-2">
                        <Link to={'/'}>Home</Link>
                        <Link to={'/agents'}>Our Agents</Link>
                        <div className="cursor-pointer pb-2" onMouseEnter={() => setDropdown('buying', true)} onMouseLeave={() => setDropdown('buying', false)} >Buying <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.buying ? 'fa-flip-vertical' : ''}`} />
                            {menus.buying &&
                                <div className="absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/calculator'}>Mortgage Calculator</Link>
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/listings'}>Residential Search</Link>
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/listings'}>Commercial Search</Link>
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/listings'}>Pre-Construction Condo Finder</Link>

                                </div>}
                        </div>
                        <div className="cursor-pointer" onMouseEnter={() => setDropdown('selling', true)} onMouseLeave={() => setDropdown('selling', false)} >Selling <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.selling ? 'fa-flip-vertical' : ''}`} />
                            {menus.selling &&
                                <div className="absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/evaluation'}>Free Home Evaluation</Link>

                                </div>}
                        </div>
                        <div className="cursor-pointer" onMouseEnter={() => setDropdown('info', true)} onMouseLeave={() => setDropdown('info', false)} >Information <FontAwesomeIcon icon={faAngleDown} className={`ml-2 ${menus.info ? 'fa-flip-vertical' : ''}`} />
                            {menus.info &&
                                <div className="absolute z-10 bg-white child:px-4 child:py-2 flex flex-col border-2 w-[200px] mt-2">
                                    <Link className="hover:bg-logo-second hover:text-white" to={'/real-estate-reports'}>Real Estate Reports</Link>

                                </div>}
                        </div>
                        <Link to={'/contact'}>Contact</Link>


                    </div>

                </div> :
                <>

                    <div className="relative w-full h-32 z-30 bg-logo-main flex justify-between items-center text-white">
                        <div className="rounded-full w-24 h-24 overflow-clip z-30 mx-4">
                            <img src={constants.LOGO} className="h-full w-full object-cover" />
                        </div>
                        <div className="z-20 transition-opacity duration-700 ease-in-out w-full h-full absolute bg-logo-main " />
                        {headerPosition === 0 ?
                            <FontAwesomeIcon onClick={() => { setHeaderPosition(100); }} className="z-30 text-2xl px-4 mx-4 cursor-pointer fixed right-4" icon={faXmark} />
                            :
                            <FontAwesomeIcon onClick={() => { setHeaderPosition(0); }} className="z-30 text-2xl px-4 mx-4 cursor-pointer fixed right-4" icon={faBars} />
                        }


                    </div>
                    <nav style={{ transition: `translate ${headerTiming}s cubic-bezier(0, .52, 0, 1)`, translate: `${headerPosition}vw`, }} className={`flex flex-col text-white items-end gap-12 px-16 py-9 bg-logo-main fixed top-0 pt-44 z-20 w-full opacity-75 h-full translate-x-[${headerPosition}vw] child:tracking-wider child:font-bold`}>
                        <Link onClick={() => { navClick() }} className={` ${path === "/" ? 'active-page' : ''}`} to={'/'}>Home {path !== '/' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                        <Link onClick={() => { navClick() }} className={` ${path === "/agents" ? 'active-page' : ''}`} to={'/agents'}>Our Agents {path !== '/agents' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                        <span onClick={() => { setDropdown('locations', !menus.locations) }} className={`cursor-pointer`}>Locations {menus.locations ? <FontAwesomeIcon className="pl-4" icon={faAngleDown} /> : <FontAwesomeIcon className="pl-4" icon={faAngleRight} />}</span>
                        {menus.locations &&
                            <>
                            {constants.LOCATIONS.map((location)=>
                             <Link onClick={() => { navClick() }} className={` ${path === formattingFunctions.slugify(location.name) ? 'active-page' : ''} text-slate-400`} to={`location/${formattingFunctions.slugify(location.name)}`}>{location.name} {path !== formattingFunctions.slugify(location.name) ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>

                            )}
                            </>}
                        <span onClick={() => { setDropdown('buying', !menus.buying) }} className={`cursor-pointer`}>Buying {menus.buying ? <FontAwesomeIcon className="pl-4" icon={faAngleDown} /> : <FontAwesomeIcon className="pl-4" icon={faAngleRight} />}</span>
                        {menus.buying &&
                            <>
                                <Link onClick={() => { navClick() }} className={` ${path === "/calculator" ? 'active-page' : ''} text-slate-400`} to={'/calculator'}>Mortgage Calculator {path !== '/calculator' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                                <Link onClick={() => { navClick() }} className={` ${path === "/listings" ? 'active-page' : ''}  text-slate-400`} to={'/listings'}>Listings {path !== '/listings' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                                <Link onClick={() => { navClick() }} className={` ${path === "/buying-guide" ? 'active-page' : ''}  text-slate-400`} to={'/buying-guide'}>Buying Guide {path !== '/listings' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                            </>}
                        <span onClick={() => { setDropdown('selling', !menus.selling) }} className={`cursor-pointer`}>Selling {menus.selling ? <FontAwesomeIcon className="pl-4" icon={faAngleDown} /> : <FontAwesomeIcon className="pl-4" icon={faAngleRight} />}</span>
                        {menus.selling &&
                            <>
                                <Link onClick={() => { navClick() }} className={` ${path === "/evaluation" ? 'active-page' : ''} text-slate-400`} to={'/evaluation'}>Free Home Evaluation {path !== '/evaluation' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                                <Link onClick={() => { navClick() }} className={` ${path === "/selling-guide" ? 'active-page' : ''} text-slate-400`} to={'/selling-guide'}>Selling Guide {path !== '/evaluation' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                            </>}
                        <span onClick={() => { setDropdown('info', !menus.info) }} className={`cursor-pointer`}>Information {menus.info ? <FontAwesomeIcon className="pl-4" icon={faAngleDown} /> : <FontAwesomeIcon className="pl-4" icon={faAngleRight} />}</span>
                        {menus.info &&
                            <>
                                <Link onClick={() => { navClick() }} className={` ${path === "/real-estate-reports" ? 'active-page' : ''} text-slate-400`} to={'/real-estate-reports'}>Free Real Estate Reports {path !== '/real-estate-reports' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                            </>}
                        <Link onClick={() => { navClick() }} className={` ${path === "/contact" ? 'active-page' : ''}`} to={'/contact'}>Contact {path !== '/contact' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>

                    </nav>

                </>
            }
        </>
    )
}
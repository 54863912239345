import React, { useState, useEffect, useRef } from "react";
import placeholder from "../assets/images/house-placeholder.webp"
import { Link, useLocation } from "react-router-dom";
import imgs from "./temp.json"
import constants from "../constants/constants";
import BouncingDotsLoader from "./loader.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faMagnifyingGlass, faX } from "@fortawesome/free-solid-svg-icons";

export default function Listings() {
    const { state } = useLocation()
    const [request, setRequest] = useState(`${constants.ENVRIONMENT === 'local' ? 'https://query.ampre.ca/' : 'https://uvhomes.aptoconnect.com/api/'}${state ? `odata/Property?$filter=contains(PropertyType, \'${capitalize(state)}\')  or contains(UnparsedAddress, \'${capitalize(state)}\')  or contains(PublicRemarks, \'${capitalize(state)}\') or contains(PropertyType, \'${capitalize(state)}\') or contains(CityRegion, \'${capitalize(state)}\')` : `odata/Property?$orderby=OriginalEntryTimestamp desc,ListingKey&$top=500`}`)
    const [listings, setListings] = useState([])
    const [media, setMedia] = useState(imgs.thumbs.value)
    const [formatted, setFormatted] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [searching, setSearching] = useState(state ? 2 : 0)
    const [currentPage, setCurrentPage] = useState(1)
    function getData(url = `${constants.ENVRIONMENT === 'local' ? 'https://query.ampre.ca/' : 'https://uvhomes.aptoconnect.com/api/'}${state ? `odata/Property?$filter=contains(PropertyType, \'${capitalize(state)}\')  or contains(UnparsedAddress, \'${capitalize(state)}\')  or contains(PublicRemarks, \'${capitalize(state)}\') or contains(PropertyType, \'${capitalize(state)}\') or contains(CityRegion, \'${capitalize(state)}\')` : `odata/Property?$orderby=OriginalEntryTimestamp desc,ListingKey&$top=500`}`) {
        var body = {

            "url": url,
            "needAuth": true,
            "bearerToken": constants.TOKEN
            // Accept: 'application/json',
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4'

        }
        return new Promise(async (resolve, reject) => {
            try {
                // fetch(`${proxyUrl}https://query.ampre.ca/odata/Property?$filter=ListAgentKey eq \'${constants.AGENT_ID}\' or CoListAgentKey eq \'${constants.AGENT_ID}\'&orderby=OriginalEntryTimestamp desc,ListingKey&$top=12`, {
                // prod
                // let data = await fetch(`https://uvhomes.aptoconnect.com/api/odata/Property(Q)$orderby=OriginalEntryTimestamp(S)desc,ListingKey(A)$top=12`)
                // dev
                let data = constants.ENVRIONMENT === 'prod' ? await fetch(formatStr(url))
                    : await fetch(`http://${constants.URL}/metaGet`, {
                        method: 'POST',
                        body: JSON.stringify(body),
                        headers: {
                            "Content-Type": "application/json"
                        }

                    })
                if (data.ok) {
                    let res = await data.json()
                    console.log(res.value)
                    resolve(res)
                } else {
                    console.log(data)
                    reject("Failed to fetch data")
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    useEffect(() => {
        if (loaded) {
            getMedia(listings?.slice(maxListings * (currentPage - 1), maxListings * (currentPage - 1) + maxListings)).then(d => {
                setMedia(d.value)
                setFormatted(formatMedia(d.value))
                console.log(d.value)


            }).catch(err => {
                console.log(err);
            })
        }

    }, [currentPage])

    function capitalize(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }
   

    function getMedia(listings) {
        // if (!listings?.length) {
        //     return {}
        // }
        console.log(listings)
        if (constants.ENVRIONMENT === 'local') {
            var temp = `https://query.ampre.ca/odata/Media?$filter=ResourceRecordKey in (`
            for (let l of listings) {
                temp = temp + '\'' + l.ListingKey + '\','

            }
            if (listings.length) {
                temp = temp.slice(0, -1)
                }
            temp = temp + ') and ImageSizeDescription eq \'Thumbnail\' and PreferredPhotoYN eq true'
        } else {

            var temp = `odata/Media(Q)$filter=ResourceRecordKey(S)in(S)(`
            for (let l of listings) {
                temp = temp + '\'' + l.ListingKey + '\','

            }
            if (listings.length) {
                temp = temp.slice(0, -1)
                }
            temp = temp + ')(S)and(S)ImageSizeDescription(S)eq(S)\'Thumbnail\'(S)and(S)PreferredPhotoYN(S)eq(S)true'

        }
        console.log(temp)

        // for development
        var body = {

            "url": temp
            ,
            "needAuth": true,
            "bearerToken": constants.TOKEN
            // Accept: 'application/json',
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjUyMCIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzMwMjI1OTA1LCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2NTIwIiwianRpIjoiMGYyMjk3OTY1Y2NjN2U4MSIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.BMvVrtwah8swIzW38UMs6yKF-HM4QO0bZu81blYx6J4'

        }

        return new Promise(async (resolve, reject) => {
            try {

                let data = constants.ENVRIONMENT === 'prod' ? await fetch(`https://uvhomes.aptoconnect.com/api/${temp}`)
                    : await fetch(`http://${constants.URL}/metaGet`, {
                        method: 'POST',
                        body: JSON.stringify(body),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                if (data.ok) {
                    let res = await data.json()
                    resolve(res)
                } else {
                    console.log(data)
                    reject("Failed to fetch data")
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    function formatMedia(media) {
        var temp = {}

        for (let img of media) {
            if (temp[img.ResourceRecordKey]) {
                temp[img.ResourceRecordKey] = [...temp[img.ResourceRecordKey], img]
            } else {
                temp[img.ResourceRecordKey] = [img]

            }
        }
        setLoaded(true);
        console.log(temp)

        return temp
    }

    useEffect(() => {

        if (!loaded.current) {

            getData(request).then(data => {
                setListings(data.value)
                listingsRef.current = data.value;

                if (data.value?.length) {
                getMedia(data.value?.slice(maxListings * (currentPage - 1), maxListings * (currentPage - 1) + maxListings)).then(d => {
                    setMedia(d.value)
                    setFormatted(formatMedia(d.value))
                    setLoaded(true)
                    


                }).catch(err => {
                    console.log(err);
                })
            } else {
                setLoaded(true)
            }
                


            }).catch(err => {
                console.log(err);
            })



            // getMedia().then(data => {
            //     console.log(data)
            //     setMedia(data.value)


            // }).catch(err => {
            //     console.log(err);
            // })
            // var temp = {}

            // for (let img of temp1) {
            //     if (temp[img.ResourceRecordKey]) {
            //         temp[img.ResourceRecordKey] = [...temp[img.ResourceRecordKey], img]
            //     } else {
            //         temp[img.ResourceRecordKey] = [img]

            //     }
            // }
            // console.log(temp)
            // setFormatted(temp);
            // setTimeout(()=>{
            //     setLoaded(true);

            // }, 3000)

        }

    }, [request])

    const [filter, setFilter] = useState('All')

    const [searchParams, setSearchParams] = useState({
        bedrooms: '',
        bathrooms: '',
        minPrice: '',
        maxPrice: ''
    })

    function updateSearch(key, val) {
        var temp = { ...searchParams }
        temp[key] = val
        setSearchParams(temp)

    }

    const listingsRef = useRef([])

    function filterListings(filter) {
        if (filter === 'All') {
            setListings(listingsRef.current)
        } else if (filter === 'Agent') {
            setListings(listingsRef.current.filter((l) => l.ListAgentKey === constants.AGENT_ID || l.CoListAgentKey === constants.AGENT_ID || l.ListOfficeKey === constants.OFFICE_ID))
        }

    }

    function runSearch(type) {
        console.log(filter, type, searchParams)
       

        // var str = `https://query.ampre.ca/odata/Property?$filter=${filter === 'Agent' ? `ListAgentKey eq \'${constants.AGENT_ID}\' or CoListAgentKey eq \'${constants.AGENT_ID}\' or ListOfficeKey eq \'${constants.OFFICE_ID}\'` : ''}${Object.entries(searchParams).filter(([k,v],i)=>!!v)?.length ? ' and ' : ''}`
        var str = `https://query.ampre.ca/odata/Property?$filter=${type === 'Agent' || (filter === 'Agent' && !type) ? `(ListAgentKey eq \'${constants.AGENT_ID}\' or CoListAgentKey eq \'${constants.AGENT_ID}\' or ListOfficeKey eq \'${constants.OFFICE_ID}\') and ` : ''}${state ? `contains(PropertyType, \'${capitalize(state)}\')  or contains(UnparsedAddress, \'${capitalize(state)}\')  or contains(PublicRemarks, \'${capitalize(state)}\') or contains(PropertyType, \'${capitalize(state)}\') or contains(CityRegion, \'${capitalize(state)}\') and ` : ''}`
      if (type !== 'Reset') {
            for (let val of Object.values(searchParams)) {
                if (val) {
                    str = str + val + ' and '
                }
            }
        }
      

        if (type !== 'Reset' && (Object.entries(searchParams).filter(([k, v], i) => !!v)?.length || type === 'Agent' || (filter === 'Agent' && !type))) {

            str = str.slice(0, -5)
            str = str + '&$top=10000'
        } else {
            str = str.slice(0, -9)
            str = str + '?$orderby=OriginalEntryTimestamp desc,ListingKey&$top=500'

        }

        console.log(str)

        getData(str).then(data => {

            setListings(data.value)
            listingsRef.current = data.value;
            getMedia(data.value?.slice(maxListings * (currentPage - 1), maxListings * (currentPage - 1) + maxListings)).then(d => {
                console.log(d.value)
                setMedia(d.value)
                setFormatted(formatMedia(d.value))
                setSearching((type && !Object.entries(searchParams).filter(([k, v], i) => !!v)?.length) || type === 'Reset' ? 3 : 2)

                setLoaded(true)


            }).catch(err => {
                console.log(err);
            })


        }).catch(err => {
            console.log(err);
        })

    }

    function clearSearch() {
        setSearching(0)
        setSearchParams({})
        setLoaded(false)
    }

    function formatStr(str) {

        const result = str
            .replaceAll(' ', '(S)')
            .replaceAll('&', '(A)')
            .replaceAll('?', '(Q)');

        return result

    }

    function numberRange(end) {
        return new Array(end).fill().map((d, i) => i + 1);
    }

    const maxPages = 5;
    const maxListings = 20;
    function getStart() {
        // get starting index of pagination numbers such that we display the same number of pages even if
        // current page is at start or end and current page is always in middle of slice
        return Math.max(0, Math.min(currentPage - Math.ceil(maxPages / 2), Math.ceil(listings?.length / maxListings) - maxPages))

    }





    return (
        <div className="max-w-[1100px] mx-auto px-7 mb-10 min-h-[35vh]">
            {!searching || searching === 3 || searching === 4 ?
                <>
                    <div className=" w-full bg-slate-100 rounded-md py-4 px-8 mt-10">
                        <h1 className="text-2xl text-black font-mont my-4">SEARCH</h1>
                        <div className="flex flex-wrap gap-x-10 ">
                            <div className="form-item max-w-36">
                                <label className="form-label ">Bedrooms</label>
                                <select className="form-input " onChange={(e) => updateSearch('bedrooms', e.target.value)} >
                                    <option value=''>Select</option>
                                    <option value='BedroomsTotal eq 1'>1</option>
                                    <option value='BedroomsTotal eq 2'>2</option>
                                    <option value='BedroomsTotal eq 3'>3</option>
                                    <option value='BedroomsTotal ge 3'>3+</option>
                                </select>
                            </div>
                            <div className="form-item max-w-36">
                                <label className="form-label">Bathrooms</label>
                                <select className="form-input " onChange={(e) => updateSearch('bathrooms', e.target.value)} >
                                    <option value=''>Select</option>
                                    <option value='BathroomsTotalInteger eq 1'>1</option>
                                    <option value='BathroomsTotalInteger eq 2'>2</option>
                                    <option value='BathroomsTotalInteger eq 3'>3</option>
                                    <option value='BathroomsTotalInteger ge 3'>3+</option>
                                </select>
                            </div>
                            <div className="form-item max-w-36">
                                <label className="form-label">Minimum Price</label>
                                <input className="form-input max-h-[31px]" placeholder="Min" onChange={(e) => updateSearch('minPrice', `ListPrice ge ${e.target.value.replace(/\D/g, '')}`)} >

                                </input>
                            </div>
                            <div className="form-item max-w-36">
                                <label className="form-label">Maximum Price</label>
                                <input className="form-input max-h-[31px]" placeholder="Max" onChange={(e) => updateSearch('maxPrice', `ListPrice le ${e.target.value.replace(/\D/g, '')}`)} >

                                </input>
                            </div>
                        </div>
                        {/* <btn onClick={() => { if (Object.entries(searchParams).filter(([k,v],i)=>!!v)?.length) { runSearch(); setSearching(1) }}} className="btn-primary my-3 inline-block">SEARCH</btn> */}
                        <btn onClick={() => { if (Object.entries(searchParams).filter(([k, v], i) => !!v)?.length) { runSearch(); setSearching(1) } }} className="btn-primary my-3 inline-block">SEARCH</btn>

                    </div>
                    <div className="flex justify-between items-end gap-x-10 flex-wrap">
                        <h1 onClick={() => console.log(filter)} className="text-4xl text-black py-8 font-mont  mt-6">RECENT LISTINGS</h1>
                        <div className="flex-auto max-w-36 mb-8">
                            <label className="form-label">Show:</label>
                            {/* <select className="form-input" onChange={(e) => { setLoaded(false); setFilter(e.target.value); setRequest(e.target.value === 'Agent' ? `${constants.ENVRIONMENT === 'local' ? 'https://query.ampre.ca/' : 'https://uvhomes.aptoconnect.com/api/'}odata/Property?$filter=ListAgentKey eq \'${constants.AGENT_ID}\' or CoListAgentKey eq \'${constants.AGENT_ID}\' or ListOfficeKey eq \'${constants.OFFICE_ID}\'&orderby=OriginalEntryTimestamp desc,ListingKey&$top=1000` : `${constants.ENVRIONMENT === 'local' ? 'https://query.ampre.ca/' : 'https://uvhomes.aptoconnect.com/api/'}odata/Property?$orderby=OriginalEntryTimestamp desc,ListingKey&$top=500`) }} > */}
                            <select className="form-input" value={filter} onChange={(e) => { setFilter(e.target.value); setSearching(4); runSearch(e.target.value); }} >

                                <option value={'All'}>All Listings</option>
                                <option value={'Agent'}>Office Listings</option>
                                {/* <option value={`${constants.ENVRIONMENT === 'local' ? 'https://query.ampre.ca/' : 'https://uvhomes.aptoconnect.com/api/'}odata/Property?$top=1000`}>My Listings</option> */}
                            </select>
                        </div>
                    </div>
                </>
                : null}



            {/* <div className={`grid ${listings?.length > 3 ? 'listing-grid' : 'listing-grid-min'} gap-9 min-h-[20vh] `}>
                            {listings.map((prop) =>
                                <Link className="w-full h-full " to={`/listing/${prop.ListingKey}`}>
                                    <div className="relative w-full aspect-[5/3] sm:aspect-[4/3] overflow-hidden">
                                        <img className="w-full h-full object-cover" src={formatted[prop.ListingKey]?.find((img) => img.Order === 0)?.MediaURL ?? formatted[prop.ListingKey]?.[0]?.MediaURL ?? placeholder} />
                                        <div className="px-2 py-1 bg-logo-second absolute bottom-0 w-full">
                                            <span className=" text-white text-sm font-mont font-semibold">{prop.CrossStreet}</span>
                                        </div>
                                    </div>
                           
                                </Link>

                            )}

                        </div>
                    } */}

            {searching === 1 || searching === 2 ?

                <>
                    <div className="flex flex-wrap gap-x-10 bg-slate-100 rounded-md pt-3 px-8 mt-10">
                        <div className="form-item max-w-36">
                            <label className="form-label ">Bedrooms</label>
                            <select className="form-input min-w-[144px]" value={searchParams.bedrooms} onChange={(e) => updateSearch('bedrooms', e.target.value)} >
                                <option value=''>Select</option>
                                <option value='BedroomsTotal eq 1'>1</option>
                                <option value='BedroomsTotal eq 2'>2</option>
                                <option value='BedroomsTotal eq 3'>3</option>
                                <option value='BedroomsTotal ge 3'>3+</option>
                            </select>
                        </div>
                        <div className="form-item max-w-36">
                            <label className="form-label">Bathrooms</label>
                            <select className="form-input min-w-[144px]" value={searchParams.bathrooms} onChange={(e) => updateSearch('bathrooms', e.target.value)} >
                                <option value=''>Select</option>
                                <option value='BathroomsTotalInteger eq 1'>1</option>
                                <option value='BathroomsTotalInteger eq 2'>2</option>
                                <option value='BathroomsTotalInteger eq 3'>3</option>
                                <option value='BathroomsTotalInteger ge 3'>3+</option>
                            </select>
                        </div>
                        <div className="form-item max-w-36">
                            <label className="form-label">Min. Price</label>
                            <input className="form-input max-h-[31px]" placeholder="Min" defaultValue={searchParams.minPrice?.replace(/\D/g, '')} onChange={(e) => updateSearch('minPrice', `ListPrice ge ${e.target.value.replace(/\D/g, '')}`)} >

                            </input>
                        </div>
                        <div className="form-item max-w-36">
                            <label className="form-label">Max. Price</label>
                            <input className="form-input max-h-[31px]" placeholder="Max" defaultValue={searchParams.maxPrice?.replace(/\D/g, '')} onChange={(e) => updateSearch('maxPrice', `ListPrice le ${e.target.value.replace(/\D/g, '')}`)} >

                            </input>
                        </div>
                        {/* <btn onClick={() => {  if (Object.entries(searchParams).filter(([k,v],i)=>!!v)?.length) { runSearch(); setSearching(1) } }} className="btn-primary my-3 inline-block self-center justify-self-end">SEARCH</btn> */}
                        <btn onClick={() => { runSearch(); setSearching(1) }} className="btn-primary my-3 inline-block self-center justify-self-end">SEARCH</btn>
                        <btn className='clear-btn self-center' onClick={() => { setSearching(4); setSearchParams({}) ; runSearch('Reset'); }}><FontAwesomeIcon className="fa-sm mr-2" icon={faX} />Clear</btn>
                    </div>
                    <div className="flex justify-between items-end gap-x-10 flex-wrap">
                        <h1 className="text-4xl text-black py-8 font-mont uppercase mt-6">{`RESULTS ${state? `FOR '${state}'` : ''}`}</h1>
                        <div className="flex-auto max-w-36 mb-8">
                            <label className="form-label">Show:</label>
                            <select value={filter} className="form-input" onChange={(e) => { setSearching(1); runSearch(e.target.value); setFilter(e.target.value) }} >

                                <option value={'All'}>All Listings</option>
                                <option value={'Agent'}>Office Listings</option>
                                {/* <option value={`${constants.ENVRIONMENT === 'local' ? 'https://query.ampre.ca/' : 'https://uvhomes.aptoconnect.com/api/'}odata/Property?$top=1000`}>My Listings</option> */}
                            </select>
                        </div>
                    </div>                </> : null}

            {!loaded || searching === 1 || searching == 4 ?
                <div className="mt-32 min-h-[20vh]">
                    <BouncingDotsLoader />
                </div>
                : null}
            {!listings?.length && loaded ?
                <h3 className="font-semibold text-align-center mt-12"><FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" /> No properties to show</h3>
                : null}

            {searching === 2 || searching === 3 || (searching === 0 && loaded) ?
                <>

                    <div className="grid listing-grid gap-9 min-h-[35vh] ">
                        {listings?.slice(maxListings * (currentPage - 1), maxListings * (currentPage - 1) + maxListings)?.map((prop) =>
                            <Link className="w-full h-full" to={`/listing/${prop.ListingKey}`}>
                                <div className="relative w-full aspect-[5/3] sm:aspect-[4/3] overflow-hidden">
                                    <img className="w-full h-full object-cover" src={formatted[prop.ListingKey]?.find((img) => img.Order === 0)?.MediaURL ?? formatted[prop.ListingKey]?.[0]?.MediaURL ?? placeholder} />
                                    <div className="px-2 py-1 bg-logo-second absolute bottom-0 w-full max-h-[56px]">
                                        <span className=" text-white text-sm font-mont font-semibold">{prop.CityRegion ?? prop.CrossStreet}</span>
                                    </div>
                                </div>

                            </Link>

                        )}
                    </div>



                </> : null
            }

            {listings?.length > maxListings && (searching === 2 || ((searching === 0 || searching === 3) && loaded)) ?
                <>
                    <div style={{ gridTemplateColumns: '1fr auto 1fr' }} className="mx-auto w-fit mt-8 grid grid-cols-[1fr auto 1fr] items-center gap-x-3">
                        {currentPage > 1 ?
                            <FontAwesomeIcon icon={faAngleLeft} className="cursor-pointer text-slate-400 fa-lg" onClick={() => setCurrentPage(currentPage - 1)} />
                            : <div />
                        }
                        <div className="flex gap-2">
                            {
                                numberRange(Math.ceil(listings?.length / maxListings)).slice(getStart(), getStart() + maxPages).map((num) =>
                                    <div key={num} onClick={() => setCurrentPage(num)} className={`rounded-full w-9 h-9 flex items-center justify-center cursor-pointer text-center ${num === currentPage ? 'text-white bg-slate-400' : 'text-slate-400'}`}>
                                        <span className={`font-bold text-lg `}>{num}</span>
                                    </div>
                                )}
                        </div>
                        {currentPage !== Math.ceil(listings?.length / maxListings) ?
                            <FontAwesomeIcon icon={faAngleRight} className="cursor-pointer text-slate-400 fa-lg" onClick={() => setCurrentPage(currentPage + 1)} />
                            : <div />
                        }

                    </div>
                    <div className="mx-auto w-fit mt-3 font-semibold text-slate-700">
                        <span>{currentPage * maxListings - maxListings + 1} to {Math.min(currentPage * maxListings, listings?.length)} of {listings?.length} properties</span>
                    </div>
                </>

                : null

            }

        </div>
    )
}